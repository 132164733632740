<template>
  <div id="register">
    <div class="logo">
      <div class="logo_box">
        <div class="logo_img">
          <img src="../assets/logo.png" alt="" />
        </div>
        <div class="title">
          <span>一站式家庭健康服务</span>
        </div>
      </div>
    </div>
    <div class="form">
      <van-form @submit="onSubmit">
        <van-cell-group>
          <van-field
            v-model="formData.phone"
            name="pattern"
            :left-icon="enroll.phone"
            placeholder="输入绑定的手机号"
          />
          <van-field
            v-model="formData.code"
            center
            :left-icon="enroll.code"
            clearable
            placeholder="请输入验证码"
          >
            <template #button>
              <van-button
                class="verification"
                size="small"
                :disabled="countFlag"
                type="primary"
                @click="sendMobile"
                >{{
                  btnMsg == null ? countNum + "s后重新发送" : btnMsg
                }}</van-button
              >
            </template>
          </van-field>
          <van-field
            v-model="formData.password"
            :left-icon="enroll.password"
            type="password"
            name="pattern1"
            placeholder="请输入密码(6-16位)"
          />
        </van-cell-group>
      </van-form>
      <div style="text-align: right;margin-top: 2vh;color:#2BB7C7;">
        <span @click="goLogin">已有账号?去登录</span>
      </div>
      <div style="margin-top: 8vh;">
        <van-button
          class="submitBtn"
          block
          type="info"
          native-type="submit"
          @click="submit"
          >立即注册</van-button
        >
      </div>
    </div>
    <div class="clause">
      <van-icon
        :name="enroll.choice"
        @click="changeIcon"
        class="icon-danxuan"
        size="12"
        v-if="!isShow"
      />
      <van-icon
        :name="enroll.readIcon"
        @click="changeIcon"
        class="icon-danxuan"
        size="12"
        v-else
      />
      <p class="agreement">
        使用"杏箱™"代表同意
        <span @click="agreement">《用户协议》</span>
        和
        <span @click="privacy">《隐私政策》</span>
      </p>
    </div>
    <van-dialog
      v-model="showIframe"
      :title="dialogTitle"
      show-cancel-button
      :showCancelButton="false"
    >
      <div class="iframe_box">
        <iframe
          name="my-iframe"
          id="my-iframe"
          :src="textContent"
          width="105%"
          height="110%"
          scrolling="yes"
          frameborder="0"
        ></iframe>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import "@/utils/flexible.js"
  export default {
    name: "register",
    data() {
      return {
        enroll: {
          phone: require("@/assets/phone.png"),
          code: require("@/assets/code.png"),
          password: require("@/assets/password.png"),
          choice: require("@/assets/unchecked.png"),
          readIcon: require("@/assets/pitchOn.png"),
        },
        formData: {
          phone: "",
          code: "",
          password: "",
          InviterCode: "",
        },
        codeMessage: "", //验证码错误提示,
        btnMsg: "发送验证码",
        countFlag: false,
        countNum: 60, // 倒计时周期
        intervalBtn: {}, //定时器
        errorMessage: "", //密码错误提示
        isShow: true,
        type: 0,
        Info: {},
        textContent: "",
        showIframe: false,
        dialogTitle: "",
      }
    },
    created() {
      let height = document.documentElement.clientHeight
      // this.formData.InviterCode = this.$route.query.InviterCode || ''
      let str = this.$route.fullPath
      let length = str.lastIndexOf("\=")

      if (length > -1) {
        str = str.substring(length + 1, str.length)
        localStorage.setItem("InviterCode", str)
        this.formData.InviterCode = str || ""
      }
    },
    methods: {
      onSubmit(values) {
        console.log("submit", values)
      },
      blurPhone(val) {
        console.log(this.formData.phone)
      },
      // 点击获取验证码
      sendMobile() {
        if (
          !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
            this.formData.phone
          )
        ) {
          this.$toast("请输入正确的手机号")
          return
        }
        this.countDown()
      },
      // 验证码提示
      code(val) {
        this.codeMessage = this.formData.code ? "" : "请输入验证码"
      },
      // 倒计时
      countDown() {
        let data = {
          PhoneNumber: this.formData.phone,
        }
        this.$http
          .post(
            localStorage.getItem("baseUrl") +
              "Mart/MartAccount/SendRegisterSMS",
            data
          )
          .then((res) => {
            if (res.body.Code == 200) {
              // 设置btn倒计时时显示的信息
              this.btnMsg = null
              // 更改btn状态
              this.countFlag = !this.countFlag
              // 设置倒计时
              this.intervalBtn = setInterval(() => {
                if (this.countNum <= 0) {
                  // 重置btn提示信息
                  this.btnMsg = "发送验证码"
                  // 清除定时器
                  clearInterval(this.intervalBtn)
                  // 更改btn状态
                  this.countFlag = !this.countFlag
                  // 重置倒计时状态
                  this.countNum = 60
                }
                // 倒计时
                this.countNum--
              }, 1000)
            } else {
              this.$toast(res.body.Error.Message)
            }
          })
      },
      submit() {
        if (this.formData.phone == "") {
          this.$toast("请输入绑定的手机号")
          return
        }
        if (
          !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
            this.formData.phone
          )
        ) {
          this.$toast("请输入正确的手机号")
          return
        }
        if (this.formData.code == "") {
          this.$toast("请输入验证码")
          return
        }

        if (this.formData.password == "") {
          this.$toast("请输入密码")
          return
        }

        if (this.formData.password.length < 6) {
          this.$toast("密码长度至少6个字符")
          return false
        }

        if (this.isShow == false) {
          this.$toast("请勾选用户协议！")
          return
        }

        let data = {
          PhoneNumber: this.formData.phone,
          Code: this.formData.code,
          Password: this.formData.password,
          InviterCode: localStorage.getItem("InviterCode"),
        }
        this.$http
          .post(
            localStorage.getItem("baseUrl") + "Mart/MartAccount/Register",
            data
          )
          .then((res) => {
            if (res.body.Code == 200) {
              this.$toast("注册成功！")
              localStorage.setItem(
                "token",
                "Bearer " + res.body.Data.access_token
              )
              this.getInfo()
            } else {
              this.$toast(res.body.Error.Message)
              return false
            }
          })
      },
      getInfo() {
        this.$http
          .get(localStorage.getItem("baseUrl") + "Mart/MartAccount/GetInfo")
          .then((res) => {
            if (res.body.Code) {
              this.Info = res.body.Data
              if (this.Info.InviterPhone == null) {
                this.getReferrer()
              }
              setTimeout(() => {
                this.$router.push({
                  path: "/download",
                })
              }, 500)
            }
          })
      },
      getReferrer() {
        this.$http
          .get(
            localStorage.getItem("baseUrl") +
              "Mart/MartAccount/SerInviter?code=" +
              this.formData.InviterCode
          )
          .then((val) => {})
      },
      goLogin() {
        this.$router.push({
          path: "/login",
        })
      },
      changeIcon() {
        this.isShow = !this.isShow
      },
      // 协议
      getUserDom(type) {
        this.$http
          .get(
            localStorage.getItem("baseUrl") +
              "Mart/Home/GetUserDom?type=" +
              type
          )
          .then((res) => {
            if (res.body.Code) {
              this.textContent = res.body.Data.Content
            }
          })
      },
      //用户协议
      agreement() {
        this.showIframe = true
        this.dialogTitle = "用户协议"
        this.getUserDom(1)
      },
      // 隐私协议
      privacy() {
        this.showIframe = true
        this.dialogTitle = "隐私政策"
        this.getUserDom(2)
      },
    },
  }
</script>

<style scoped lang="scss">
  * {
    margin: 0;
  }

  #register {
    width: 100%;
    height: 100vh;
    background-image: url("../assets/backImage1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    .logo {
      padding: 19vh 0 7vh 0;

      .logo_box {
        color: #333333;

        .logo_img {
          width: 89px;
          height: auto;
          margin: 0 auto;
          margin-bottom: 19px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .form {
      padding: 15px;

      .van-cell-group {
        border-radius: 8px;
      }

      .van-cell {
        display: flex;
        align-items: center;
        border-radius: 8px;
        height: 50px;
      }

      .van-cell::v-deep .van-icon {
        font-size: 20px !important;
      }

      .van-cell::v-deep .van-field__left-icon {
        margin-right: 20px;
      }
    }

    .clause {
      line-height: 12px;
      display: flex;
      white-space: pre;
      align-items: center;
      justify-content: center;
      margin-top: 5vh;
    }

    .submitBtn {
      border-radius: 4px;
      background-color: #2bb7c7;
      border: none;
    }

    .van-dialog {
      width: 100%;
      height: 80%;
      overflow: hidden;

      .iframe_box {
        width: 100%;
        height: 100%;
      }
    }

    .van-dialog::v-deep .van-dialog__content {
      height: 80%;
    }

    .van-dialog::v-deep .van-dialog__footer {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
</style>
