<template>
	<div id="forPoints" @click.stop="isShow=false">
		<div class="title_Dom">
			<div class="tilte_img" @click="gotoPointDetail">
				<img src="@/assets/UI-02.png" alt="" />
				<span>杏叶:{{Info.Score}}</span>
			</div>
			<div class="integration">
				<span @click.stop="integrationBtn">积分规则>>>></span>
			</div>
		</div>
		<div class="explain">
			<div class="explain_img" v-if="isShow">
				<img :src="IntegralPic" alt="" />
			</div>
			<div class="explain_img" v-if="showWater==1">
				<img src="@/assets/UI-06.png" alt="" />
			</div>
			<div class="explain_img" v-if="showWater==2">
				<img src="@/assets/UI-05.png" alt="" />
			</div>
		</div>
		<div class="bottom_Dom">
			<div class="tree_Dom">
				<img src="@/assets/1-1.gif" alt="">
			</div>
			<div class="bottom_Box">
				<div class="left" @click="backMini">
					<img src="@/assets/left.png" alt="">
				</div>
				<div class="right" @click="water">
					<img src="@/assets/UI-07.png" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import '@/utils/flexible.js'

	export default {
		name: "forPoints",
		data() {
			return {
				type: 0, //1.苹果  2.安卓  3.windows
				isShow: false,
				showWater: -1,
				Info: {},
				IntegralPic: '',

			}
		},
		created() {
			var phone = navigator.userAgent
			if (phone.indexOf("Android") > -1 || phone.indexOf("Linux") > -1) {
				this.type = 1
			} else if (phone.indexOf("iPhone") > -1) {
				this.type = 2
			} else if (phone.indexOf("Windows Phone") > -1) {
				this.type = 3
			} else {
				this.type = 0
			}

		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				let token = this.$route.query.token;
				if (!token || token == undefined || token == null) {
					localStorage.setItem('token', token)
				} else {
					localStorage.setItem('token', 'Bearer ' + token)
					this.$http.get(localStorage.getItem("baseUrl") + "Mart/MartAccount/GetInfo").then(res => {
						if (res.body.Code) {
							this.Info = res.body.Data
						}
					})
				}
			},
			gotoPointDetail() {
				location.href = 'medicalkit://gotoPointDetail'
			},
			integrationBtn() {
				this.$http.get(localStorage.getItem("baseUrl") + "Mart/Home/GetScoreInfoPic").then(res => {
					if (res.body.Code) {
						this.IntegralPic = res.body.Data
						this.isShow = true
					}
				})
			},
			water() {
				let token = localStorage.getItem('token')
				if (!token || token == undefined || token == null) {
					location.href = 'medicalkit://showLogin'
				} else {
					if (this.Info.RightsUser === 1) {
						this.$http
							.get(
								localStorage.getItem("baseUrl") +
								"Mart/MartAccount/SignInIntegral"
							).then(res => {
								let val = res.body.Data
								this.showWater = val.Code
								this.Info.Score = val.Score
								setTimeout(() => {
									this.showWater = -1
								}, 2000)
							})
					} else {
						if (!this.Info.MedicalKitID) {
							location.href = 'medicalkit://gotoBindBox'
						} else {
							this.$http
								.get(
									localStorage.getItem("baseUrl") +
									"Mart/MartAccount/SignInIntegral"
								).then(res => {
									let val = res.body.Data
									this.showWater = val.Code
									this.Info.Score = val.Score
									setTimeout(() => {
										this.showWater = -1
									}, 2000)
								})
						}
					}

				}
			},
			backMini() {
				location.href = 'medicalkit://gotoShopChange'

			}
		},
	}
</script>

<style scoped lang="scss">
	* {
		margin: 0;
		/* 禁止长按屏幕复制内容*/
		  -webkit-touch-callout: none;
		  -webkit-user-select: none;
		  -moz-user-select:none;
		  -ms-user-select:none;
		  user-select:none;
	}
	input , img{
	  /*用上边的禁止复制方法后 此时在ios手机有问题，所有的输入框自动失去焦点无法输入内容,*/
	  -webkit-user-select:auto;
	}

	#forPoints {
		width: 100vw;
		height: 100vh;
		background-image: url("https://api.szjfznkj.com/h5/signinbg.jpg");
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position: center;

		.title_Dom {
			padding: 1.067rem 0 0 0.533rem;
			height: auto;

			.tilte_img {
				width: 4.267rem;
				height: 1.1rem;
				position: relative;
				font-size: 0.373rem;

				span {
					position: absolute;
					top: 0.3rem;
					left: 1.2rem;
					color: #fff;
				}

				img {
					width: 100%;
					height: 100%;
				}
			}

			.integration {
				color: #b57200;
				margin-left: 15px;
				font-size: 0.373rem;
				height: auto;
			}
		}

		.explain {
			position: relative;
			height: 55vh;

			.explain_img {
				position: absolute;
				left: 50%;
				bottom: 0;
				transform: translate(-50%);
				z-index: 111;
				height: auto;
				width: 6.667rem;
				height: auto;
				// width: 6.667rem;
				// height: auto;
				// margin: 0 auto;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.bottom_Dom {

			.tree_Dom {
				position: fixed;
				bottom: 6.4rem;
				left: 4.533rem;
				transform: translate(-50%);

				img {
					width: 1.867rem;
					height: auto;
				}
			}

			.bottom_Box {
				position: fixed;
				bottom: 1rem;
				left: 0;
				display: flex;
				justify-content: space-between;
				width: 100vw;

				height: auto;

				.left {
					width: 1.867rem;
					height: 1.867rem;
					margin-left: 0.533rem;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.right {
					width: 1.867rem;
					height: 1.867rem;
					margin-right: 0.533rem;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
</style>
