import Vue from "vue"
import VueRouter from "vue-router"

import register from "../views/register.vue"
import forPoints from "../views/forPoints/forPoints.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/register"),
  },
  {
    path: "/forPoints",
    name: "forPoints",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/forPoints/forPoints"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/login/login"),
  },
  {
    path: "/QRCode",
    name: "QRCode",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/QRCode/QRCode"),
  },
  {
    path: "/productDetails",
    name: "productDetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/productDetails/productDetails"
      ),
  },
  {
    path: "/share",
    name: "share",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/share/share"),
  },
  {
    path: "/download",
    name: "download",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/download/download"),
  },
  {
    path: "/guide",
    name: "guide",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/guide/guide"),
  },
  {
    path: "/downlodas",
    name: "downlodas",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/downlodas/downlodas"),
  },
  {
    path: "/xiazai",
    name: "xiazai",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/xiazai/xiazai"),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
