import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import VueResource from 'vue-resource';
Vue.use(Vant);
Vue.use(VueResource);
Vue.config.ignoredElements=['wx-open-launch-weapp']
Vue.config.productionTip = false
Vue.http.interceptors.push((request, next) => {
	let token = localStorage.getItem('token')
	request.headers.set('Authorization', token)
	next(function(response) {
		return response;

	});
})

new Vue({
	router,
	render: h => h(App)

}).$mount('#app')
